@import url('https://fonts.googleapis.com/css?family=Comfortaa&display=swap');


body {
  margin: 0;
  font-size: 12px;


  font-family: 'Montserrat', Comfortaa, sans-serif !important;

  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
  /*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
  /*  sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*code {*/
/*  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',*/
/*    monospace;*/
/*}*/

/*body {*/
/*}*/

a {
  color: #3ab0e4;
}

p {
  line-height: 1.4;
  font-size: 16px;
}

h1 {
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 300;
}

h2 {
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 500;
}

h3 {
  font-family: 'Comfortaa', cursive;
  font-size: 16px;
  font-weight: 700;
}

#onetrust-consent-sdk #onetrust-accept-btn-handler, #onetrust-banner-sdk #onetrust-reject-all-handler { border-radius:50px; }
#onetrust-banner-sdk #onetrust-policy-text { font-size: 1em !important; font-weight: 300; }